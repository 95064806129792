import ConvenienceExtractModel from "../Model/ConvenienceExtractModel";

export default class ConvenienceExtractController {
  convenienceExtract = new ConvenienceExtractModel();

  getHistory(
    catalogPartnerId: number,
    searchTerm = null,
    page = 0,
    startDate = null,
    endDate = null,
    linesPerPage = 24,
    direction = "DESC",
    orderBy = "id",
  ) {
    return this.convenienceExtract
      .getConvenienceHistory(
        catalogPartnerId,
        searchTerm,
        page,
        startDate,
        endDate,
        linesPerPage,
        direction,
        orderBy,
      )
      .then((list) => {
        return list.data;
      });
  }

  getTotalValues(catalogPartnerId: number, startDate: string, endDate: string, searchTerm?: string) {
    return this.convenienceExtract
      .getTotalValues(catalogPartnerId, startDate, endDate, searchTerm)
      .then((list) => {
        return list.data;
      });
  }

  getCashbackByConvenience(catalogPartnerId: number, cpf: string) {
    return this.convenienceExtract
      .getCashbackByConvenience(catalogPartnerId, cpf)
      .then((list) => {
        return list.data;
      });
  }

  postExchangeCashback(data) {
    return this.convenienceExtract
      .postExchangeCashback(data)
      .then((list) => {
        return list.data;
      });
  }

}
