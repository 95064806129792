import axios from 'axios';
const API_URL = process.env.VUE_APP_API_URL;

export default class ConvenienceExtractModel {
    async getConvenienceHistory (
        catalogPartnerId: number,
        searchTerm: string,
        page: number,
        startDate: string,
        endDate: string,
        linesPerPage: number,
        direction: string,
        orderBy: string,
    ) {
        let url = `${API_URL}/convenience-transaction/?catalogPartnerId=${catalogPartnerId}&page=${page}&linesPerPage=${linesPerPage}&direction=${direction}&orderBy=${orderBy}`;

        if (searchTerm) {
            url += `&searchTerm=${searchTerm}`;
        }

        if (startDate) {
            url += `&startDate=${startDate}`;
        }

        if (endDate) {
            url += `&endDate=${endDate}`;
        }

        return axios.get(url);
    }
      

    async getTotalValues(catalogPartnerId: number, startDate: string, endDate: string, searchTerm?: string) {
        if (searchTerm) {
            return axios.get(
                `${API_URL}/convenience-transaction/total-amounts/?startDate=${startDate}&endDate=${endDate}&catalogPartnerId=${catalogPartnerId}&searchTerm=${searchTerm}`
            );
        } else {
            return axios.get(
                `${API_URL}/convenience-transaction/total-amounts/?startDate=${startDate}&endDate=${endDate}&catalogPartnerId=${catalogPartnerId}`
            );
        }
    }

    async getCashbackByConvenience(catalogPartnerId: number, cpf: string) {
        return axios.get(`${API_URL}/convenience-transaction/get-data-client-cashback?clientCpf=${cpf}&catalogPartner=${catalogPartnerId}`);
    }


    async postExchangeCashback (data) {
        return axios.post(`${API_URL}/convenience-transaction`, data);
    }
}